<template>
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Awaiting Approval 🔐
        </h2>
        <p class="mb-2">
          Your account needs approval before we give you access to the dashboard
        </p>
        <div>
          <b-button
            variant="primary"
            class="mx-1 mb-1 btn-sm-block"
            to="/"
          >Try Again</b-button>
          <b-button
            variant="primary"
            class="mx-1 mb-1 btn-sm-block"
            @click="logout(null)"
          >Logout</b-button>
        </div>
        <b-img
          fluid
          :src="imgUrl"
          alt="Awaiting approval page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },

    activeUserInfo() {
      return this.$store.state.auth.ActiveUser
    },
    otherProfiles() {
      return this.$store.state.auth.OtherProfiles
    },
  },
  methods: {
    logout(index = null, $event) {
      if (index !== null) {
        const OtherProfiles = [...this.otherProfiles]
        OtherProfiles.splice(index, 1)
        this.$store.commit('auth/CHANGE_MULTIPLE_PROFILES', OtherProfiles, {
          root: true,
        })

        this.$toast(
          {
            component: this.toastContent,
            props: {
              title: 'Account Information',
              icon: 'AlertCircleIcon',
              text: 'You have logged out of the account successfully',
              variant: 'success',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else if (this.otherProfiles.length > 0) {
        const OtherProfiles = [...this.otherProfiles]
        const profileTo = this.$store.state.OtherProfiles[0]

        OtherProfiles.splice(0, 1)

        this.$store.commit('auth/CHANGE_MULTIPLE_PROFILES', OtherProfiles, {
          root: true,
        })

        this.$store.commit('auth/UPDATE_USER_INFO', profileTo, { root: true })
        localStorage.setItem('accessToken', profileTo.token)
        window.location.reload()
      } else {
        // localStorage.clear()
        this.$store.commit('auth/UPDATE_USER_INFO', null, { root: true })
        localStorage.removeItem('userInfo')
        localStorage.removeItem('email')
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

        // This is just for demo Purpose. If user clicks on logout -> redirect
        this.$router.push({ name: 'auth-login' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
